module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ce2e721f8d8a471898c00035d6d7f3ff@o409936.ingest.sentry.io/4503925623226368","tracesSampleRate":1,"release":"gatsby@2025-01-08T10:41:05.902Z","environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 36rem)","md":"(min-width: 48rem)","lg":"(min-width: 62rem)","xl":"(min-width: 75rem)"}},
    },{
      plugin: require('../../../packages/gatsby-theme-menus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-apolloclient/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/acm/gatsby-browser.js'),
      options: {"plugins":[],"core":{"sourceStrapi":{"themes":{"rating":true,"pagebuilder":true}}}},
    },{
      plugin: require('../../../packages/gatsby-theme-pagebuilder/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bandshirt.nl","short_name":"bandshirt","start_url":"/?pwa=true","background_color":"#fff","theme_color":"#049b34","display":"minimal-ui","icon":"src/favicons/bandshirt.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c36113754850ce429037a4d66bdbf611"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DNS7ZS7NJ2","UA-18510282-15"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
